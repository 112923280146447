export default {
    methods: {
        showSuccessAddedCartItem(itemName, itemQuantity = 1) {
            const toastedMessage = 'Товар "'+itemName+'" (<b style="font-weight: 700">'+itemQuantity+'шт</b>) успешно добавлен в корзину!';

            this.$toasted.global.add_to_cart_message({
                message: toastedMessage
            })
        }
    },

}
