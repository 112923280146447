<template>
    <div v-if="loaded" class="mini_cart_wrapper" @click="toggleMiniCart()">
        <a href="javascript:void(0)" :title="isCartEmpty ? 'Корзина' : 'Корзина пуста :('"><span class="lnr lnr-cart"></span></a>
        <span v-if="cartTotalQuantity > 0" class="cart_quantity">{{ cartTotalQuantity }}</span>
        <!--mini cart-->
        <div v-if="cartItems.length" class="mini_cart" :style="miniCartStyles">

            <div v-for="(product, index) in cartItems" v-bind:key="product.id" class="cart_item">
                <div class="cart_img">
                    <a href="#"><img :src="product.attributes.imgSrc" alt=""></a>
                </div>
                <div class="cart_info">
                    <a href="#">{{ product.name }} </a>
                    <span class="quantity">Кол.: {{ product.quantity }}</span>
                    <span class="price_cart">{{ product.price }} грн.</span>
                </div>
                <div class="cart_remove">
                    <a href="#" title="Удалить из корзины">
                        <i class="lnr lnr-trash" @click.stop="deleteProduct(index)"></i>
                    </a>
                </div>
            </div>

            <div class="mini_cart_table">
                <div class="cart_total mt-10">
                    <span>Общая сумма заказа:</span>
                    <span class="price">{{ cartTotal }} грн.</span>
                </div>
            </div>

            <div class="mini_cart_footer">
                <div class="cart_button">
                    <a href="/cart">Перейти в корзину</a>
                </div>
                <div class="cart_button">
                    <a href="/checkout">Оформить заказ</a>
                </div>
            </div>

        </div>
        <!--mini cart end-->
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        data() {
            return {
                miniCartStyles: {
                    display: ''
                }
            }
        },
        methods: {
            deleteProduct: function (index) {
                this.$store.dispatch('deleteCartItem', index)
            },
            toggleMiniCart() {
                if($(window).width() < 991){
                    $('.mini_cart').slideToggle('medium');
                }
            }
        },
        computed: mapState({
            cartItems: state => state.cartItems,
            loaded: state => state.cartState,
            cartTotal() {
                return this.$store.getters.cartTotal
            },
            cartTotalQuantity: function () {
                let quantity = 0
                this.cartItems.forEach((p) => (quantity += p.quantity))
                return quantity
            },
            isCartEmpty() {
                return this.cartItems.length;
            }
        }),
        created() {
            this.$store.dispatch('getCartItems')
        },
        mounted() {
            this.toggleMiniCart()
        }
        //computed: mapState(['products'])
    }
</script>
